.DEV {
  outline: 0.5px solid rgba(255, 0, 0, 0.5);
  outline-offset: 4px;
}

.disableAndDim {
  opacity: 0.4;
  pointer-events: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.DirectionIcon {
  pointer-events: none;
}

/** Leaflet Tooltips */
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  display: none;
}

.all-copy {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}

/*
 * react-leaflet is silly, you have to manually style the divs it creates
 * https://github.com/PaulLeCam/react-leaflet/blob/master/example/index.html
 * https://github.com/PaulLeCam/react-leaflet/issues/108
 */
.leaflet-container {
  height: 100%;
  width: 100%;
}

.CircleIconMarker {
  border-radius: 50%;
  background-color: #2389c9;
}

.CircleIconMarker.newSnappedStop {
  background-color: #364046;
}

.CircleIconMarker.newStop > div,
.CircleIconMarker.newSnappedStop > div {
  background-color: #fff;
  border-radius: 50%;
  width: 70%;
  height: 70%;
  left: 15%;
  top: 15%;
  position: absolute;
}
